import React from "react";
import Header from "../../../components/header";
import HeroBanner from "../../../components/HeroBanner";
import Layout from "../../../components/layout";
import WithProviders from "../../../components/WithProviders";
import { Message } from "../../../lib/getMessage";

import CloudMigrationImage from "../../images/cloud-migration-image.png";
import CloudMigrationImage1 from "../../images/cloud-migration-image@2x.png";
import CloudMigrationImage2 from "../../images/cloud-migration-image@3x.png";

import RequirementsImage from "../../images/cloud-migration-requirements.png";
import RequirementsImage1 from "../../images/cloud-migration-requirements@2x.png";
import RequirementsImage2 from "../../images/cloud-migration-requirements@3x.png";

import EnviornmentImage from "../../images/cloud-migration-enviornment.png";
import EnviornmentImage1 from "../../images/cloud-migration-enviornment@2x.png";
import EnviornmentImage2 from "../../images/cloud-migration-enviornment@3x.png";

import PlanningImage from "../../images/cloud-migration-planning.png";
import PlanningImage1 from "../../images/cloud-migration-planning@2x.png";
import PlanningImage2 from "../../images/cloud-migration-planning@3x.png";

import MaintenanceImage from "../../images/cloud-migration-maintenance.png";
import MaintenanceImage1 from "../../images/cloud-migration-maintenance@2x.png";
import MaintenanceImage2 from "../../images/cloud-migration-maintenance@3x.png";

import ProductWithImage from "../../../components/ProductWithImage";
import ListView from "../../../components/ListView";

import "./style.css";
const PartnerLayout = (props) => (
  <div className={props.name} name={props.name}>
    <div className="image">
      <img
        src={props.image.normal}
        srcSet={`${props.image.xx} 2x, ${props.image.xxx} 3x`}
        alt="media"
        loading="lazy"
      />
    </div>
    <div className="description">
      <p>{props.description}</p>
    </div>
  </div>
);

const SelfHelpDesc = (
  <ListView
    lists={[
      <Message dictKey="services.cloudmigration.description.item1" />,
      <Message dictKey="services.cloudmigration.description.item2" />,
      <Message dictKey="services.cloudmigration.description.item3" />,
    ]}
  />
);

const CloudMigration = (props) => {
  const { language, updateLanguage, direction, location } = props;
  const isDirectionRTL = direction === "rtl";
  return (
    <Layout
      direction={direction}
      showFooterForm
      language={language}
      location={location}
    >
      <div>
        <Header
          current="service"
          language={language}
          updateLanguage={updateLanguage}
          direction={direction}
        />
        <div className="srvc-cloud-migrt">
          <HeroBanner
            title={<Message dictKey="services.cloudmigration.title" />}
            className="cloud-migration-banner"
          />
          <section className="cloud-migration-main">
            <ProductWithImage
              name="cloud-migration-img-desc"
              className="cloud-migration-img-desc"
              description={[
                {
                  type: "p",
                  val: (
                    <Message dictKey="services.cloudmigration.description1" />
                  ),
                  id: 1,
                },
                {
                  type: "component",
                  val: SelfHelpDesc,
                  id: 1,
                },
              ]}
              image={{
                normal: CloudMigrationImage,
                xx: CloudMigrationImage1,
                xxx: CloudMigrationImage2,
              }}
              isDirectionRTL={isDirectionRTL}
            />
            <div className="cloud-migration-content">
              <p>
                <Message dictKey="services.cloudmigration.description2" />
              </p>
            </div>
          </section>
        </div>
        <div>
          <section className="trusted-partners">
            <div className="trusted-partners-heading">
              <h2>
                <Message dictKey="services.cloudmigration.trustedpartner.heading" />
              </h2>
            </div>
            <div className="trusted-partners-main">
              <PartnerLayout
                name="requirements"
                description={
                  <Message dictKey="services.cloudmigration.trustedpartner.description1" />
                }
                image={{
                  normal: RequirementsImage,
                  xx: RequirementsImage1,
                  xxx: RequirementsImage2,
                }}
              />
              <PartnerLayout
                name="environment"
                description={
                  <Message dictKey="services.cloudmigration.trustedpartner.description2" />
                }
                image={{
                  normal: EnviornmentImage,
                  xx: EnviornmentImage1,
                  xxx: EnviornmentImage2,
                }}
              />
              <PartnerLayout
                name="planning"
                description={
                  <Message dictKey="services.cloudmigration.trustedpartner.description3" />
                }
                image={{
                  normal: PlanningImage,
                  xx: PlanningImage1,
                  xxx: PlanningImage2,
                }}
              />
              <PartnerLayout
                name="maintenance"
                description={
                  <Message dictKey="services.cloudmigration.trustedpartner.description4" />
                }
                image={{
                  normal: MaintenanceImage,
                  xx: MaintenanceImage1,
                  xxx: MaintenanceImage2,
                }}
              />
            </div>
          </section>
        </div>
      </div>
    </Layout>
  );
};

export default WithProviders(CloudMigration);
