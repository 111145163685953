import React from "react";

import "./style.css";
const ListView = (props) => {
  const { heading, lists, isDirectionRTL } = props;
  let list = lists?.map((elm, idx) => {
    return <li key={elm + idx}>{elm}</li>;
  });
  return (
    <div
      className={`common-list-view ${
        isDirectionRTL ? "common-list-view-rtl" : ""
      }`}
    >
      {heading ? <h2>{heading}</h2> : null}
      <ul>{list}</ul>
    </div>
  );
};

export default ListView;
